import { ReadonlyJSONObject, ReadonlyJSONValue, ReadTransaction, WriteTransaction } from "@workcanvas/reflect";
import consts from "../consts";
import {
  authenticatedDelete,
  authenticatedPut,
  canvasElementPrefix,
  createElementId,
  getUnixTimestampUTC,
  sanityMutationId,
  validateSchema,
} from "../util/utils";
import { drawingSchema } from "./schemas/drawing";
import { AttachedConnector, CanvasElement, Point } from "./schemas/canvas-element";
import { shapeSchema } from "./schemas/shape";
import { stickyNoteSchema } from "./schemas/sticky-note";
import { textBlockSchema } from "./schemas/textBlock";
import { connectorSchema } from "./schemas/connector";
import { fileSchema } from "./schemas/file";
import { taskCardSchema } from "./schemas/task-card";
import { frameSchema } from "./schemas/frame";
import { commentThreadSchema } from "./schemas/comment-thread";
import { mindmapNodeSchema } from "./schemas/mindmap";
import { integrationItemSchema } from "./schemas/integration-item";
import { mindmapOrgChartNodeSchema } from "./schemas/mindmap-org-chart";
import { orgChartSchema } from "./schemas/org-chart";
import { cardStackSchema } from "./schemas/card-stack";
import { sanitizeUrl } from "../util/sanitize-url";
import { liveIntegrationSchema } from "./schemas/live-integration";
import { applyPatches, enablePatches, Patch } from "immer";
import { timelineSchema } from "./schemas/timeline";
import { tableElementSchema } from "./schemas/table";

enablePatches();

function parseElementByType(type: string, value: ReadonlyJSONValue | undefined) {
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE:
      return validateSchema(shapeSchema, value);
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
      return validateSchema(stickyNoteSchema, value);
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
      return validateSchema(textBlockSchema, value);
    case consts.CANVAS_ELEMENTS.DRAWING:
      return validateSchema(drawingSchema, value);
    case consts.CANVAS_ELEMENTS.CONNECTOR:
      return validateSchema(connectorSchema, value);
    case consts.CANVAS_ELEMENTS.FILE:
      return validateSchema(fileSchema, value);
    case consts.CANVAS_ELEMENTS.TASK_CARD:
      return validateSchema(taskCardSchema, value);
    case consts.CANVAS_ELEMENTS.FRAME:
      return validateSchema(frameSchema, value);
    case consts.CANVAS_ELEMENTS.COMMENT:
      return validateSchema(commentThreadSchema, value);
    case consts.CANVAS_ELEMENTS.MINDMAP:
      return validateSchema(mindmapNodeSchema, value);
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
      return validateSchema(mindmapOrgChartNodeSchema, value);
    case consts.CANVAS_ELEMENTS.INTEGRATION:
      return validateSchema(integrationItemSchema, value);
    case consts.CANVAS_ELEMENTS.ORG_CHART:
      return validateSchema(orgChartSchema, value);
    case consts.CANVAS_ELEMENTS.CARD_STACK:
      return validateSchema(cardStackSchema, value);
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
      return validateSchema(liveIntegrationSchema, value);
    case consts.CANVAS_ELEMENTS.TIMELINE:
      return validateSchema(timelineSchema, value);
    case consts.CANVAS_ELEMENTS.TABLE:
      return validateSchema(tableElementSchema, value);
    default:
      console.error("failed to parse element - invalid type " + type);
      return null;
  }
}

export async function getElement(tx: ReadTransaction, id: string, type?: string): Promise<CanvasElement | null> {
  const idOnly = type ? id : id.split("-")[1];
  const typeOnly = type || id.split("-")[0];
  const elementID = type ? `${typeOnly}-${idOnly}` : `${typeOnly}-${idOnly}`;
  const jv = await tx.get(key(elementID));
  if (!jv) {
    console.log(`Specified shape ${elementID} not found.`);
    return null;
  }
  return parseElementByType(typeOnly, jv);
}

export function putElement(
  tx: WriteTransaction,
  { id, element }: { id: string; element: CanvasElement }
): Promise<void> {
  const next = { ...(element as ReadonlyJSONObject), lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

export async function putElements(
  tx: WriteTransaction,
  { ids, elements }: { ids: string[]; elements: { element: any }[] }
): Promise<void> {
  const lastModifiedTimestamp = getUnixTimestampUTC();
  await Promise.all(
    ids.map((id, index) =>
      authenticatedPut(tx, key(id), { ...(elements[index].element as ReadonlyJSONObject), lastModifiedTimestamp })
    )
  );
}

export async function sanityCheck(
  tx: WriteTransaction,
  { originTimestamp }: { originTimestamp: number }
): Promise<void> {
  return tx.set(`${sanityMutationId}-${tx.clientID}`, { originTimestamp });
}

export async function putAnys(tx: WriteTransaction, items: ReadonlyArray<[string, any]>): Promise<void> {
  const lastModifiedTimestamp = getUnixTimestampUTC();
  if (tx.location === "client" || !tx.auth?.isReadOnly) {
    await Promise.all(
      items.map(([key, value]) => tx.set(key, { ...value, lastModifiedTimestamp } as ReadonlyJSONObject))
    );
  }
}

export async function removeMany(tx: WriteTransaction, { ids }: { ids: string[] }) {
  for (const id of ids) {
    await authenticatedDelete(tx, key(id));
  }
}

export async function addAttachedConnector(
  tx: WriteTransaction,
  {
    id,
    elementType,
    connectorId,
    attachedConnector,
  }: {
    id: string;
    elementType: string;
    connectorId: string;
    attachedConnector: AttachedConnector;
  }
): Promise<void> {
  const element = await getElement(tx, id, elementType);
  if (element && element.attachedConnectors) {
    let newElement = { ...element, attachedConnectors: { ...element.attachedConnectors } };
    newElement.attachedConnectors[connectorId] = attachedConnector;
    await putElement(tx, { id, element: newElement });
  }
}

export async function removeAttachedConnector(
  tx: WriteTransaction,
  {
    id,
    elementType,
    connectorId,
    anchorIndex,
  }: {
    id: string;
    elementType: string;
    connectorId: string;
    anchorIndex: number;
  }
): Promise<void> {
  const element = await getElement(tx, id, elementType);
  if (element && element.attachedConnectors) {
    if (element.attachedConnectors.hasOwnProperty(connectorId)) {
      if (element.attachedConnectors[connectorId]!.index === anchorIndex) {
        delete element.attachedConnectors[connectorId];
      }
      await putElement(tx, { id, element: element });
    }
  }
}

export async function copyAttachedConnector(
  tx: WriteTransaction,
  {
    copyFromId,
    elementType,
    elementCopy,
    connectorId,
    copiedConnectorId,
  }: {
    copyFromId: string;
    elementType: string;
    elementCopy: { id: string; element: CanvasElement };
    connectorId: string;
    copiedConnectorId: string;
  }
): Promise<void> {
  const element = await getElement(tx, copyFromId, elementType);
  if (element && element.attachedConnectors) {
    const anchoredShape = element.attachedConnectors[connectorId]!;
    const copiedAnchoredShape = {
      lineId: copiedConnectorId,
      offsetX: anchoredShape.offsetX,
      anchorMode: anchoredShape.anchorMode,
      index: anchoredShape.index,
      position: anchoredShape.position,
    } as AttachedConnector;
    elementCopy.element.attachedConnectors![copiedConnectorId] = copiedAnchoredShape;
    await putElement(tx, {
      id: `${elementType}-${elementCopy.id}`,
      element: elementCopy.element,
    });
  }
}

export async function putAttachedConnectorDragState(
  tx: WriteTransaction,
  {
    id,
    elementType,
    connectorId,
    draggable,
  }: {
    id: string;
    elementType: string;
    connectorId: string;
    draggable: boolean;
  }
): Promise<void> {
  const element = await getElement(tx, id);
  if (element && element.attachedConnectors && element.attachedConnectors[connectorId]) {
    // element.attachedConnectors[connectorId]!.dragDisabled = !draggable;
    await putElement(tx, { id, element: element });
  }
}

export async function changeElementProperties(
  tx: WriteTransaction,
  { id, properties }: { id: string; properties: { key: string; value: any }[] }
): Promise<void> {
  const element: any = await getElement(tx, id);
  if (element) {
    let didModify = false;
    for (const { key, value } of properties) {
      if (!value) {
        didModify = true;
        delete element[key];
      } else if (element[key] !== value) {
        didModify = true;
        element[key] = key === "link" ? sanitizeUrl(value) : value;
      }
    }
    if (didModify) {
      await putElement(tx, { id, element: element as CanvasElement });
    }
  }
}

export async function deleteElement(tx: WriteTransaction, id: string): Promise<void> {
  await authenticatedDelete(tx, key(id));
}

export async function moveElement(
  tx: WriteTransaction,
  { id, elementType, dx, dy }: { id: string; elementType?: string; dx: number; dy: number }
): Promise<void> {
  const element = await getElement(tx, id, elementType);
  if (element) {
    element.x = dx;
    element.y = dy;
    await putElement(tx, { id, element: element });
  }
}

export type ElementDragInfo = {
  id: string;
  position: Point;
};
export async function moveElements(
  tx: WriteTransaction,
  { multiDragInfo }: { multiDragInfo: ElementDragInfo[] }
): Promise<void> {
  for (const dragInfo of multiDragInfo) {
    const element = await getElement(tx, dragInfo.id);
    if (element) {
      element.x = dragInfo.position.x;
      element.y = dragInfo.position.y;
      await putElement(tx, { id: dragInfo.id, element: element });
    }
  }
}

export type ElementMoveInfo = {
  ids: string[];
  dx: number;
  dy: number;
};
export async function moveElementsByDelta(tx: WriteTransaction, moveInfo: ElementMoveInfo): Promise<void> {
  await Promise.all(
    moveInfo.ids.map((id) =>
      getElement(tx, id).then((element) => {
        if (element) {
          element.x += moveInfo.dx;
          element.y += moveInfo.dy;
          return putElement(tx, { id: id, element: element });
        }
      })
    )
  );
}

export type ElementDrawProps = {
  x?: number;
  y?: number;
  scaleX?: number;
  scaleY?: number;
  rotate?: number;
  hidden?: boolean;
  zIndexLastChangeTime?: number;
  groupId?: string;
  groupHistory?: string[];
  frameId?: string;
  sortIndex?: number;
  containerId?: string | null;
  itemsDates?: {
    [k: string]: number;
  };
};

export async function attachToFrame(
  tx: WriteTransaction,
  { ids, frameId }: { ids: string[]; frameId: string | null }
): Promise<void> {
  return Promise.all(
    ids.map(async (id) => {
      const element = await getElement(tx, id);
      if (!element) {
        return;
      }
      const { frameId: oldFrameId, ...rest } = element;
      if (frameId) {
        (rest as any).frameId = frameId;
      }
      await putElement(tx, { id, element: rest });
    })
  ).then();
}

export async function changeElements(
  tx: WriteTransaction,
  { info }: { info: [string, ElementDrawProps][] }
): Promise<void> {
  for (const [id, props] of info) {
    const element = await getElement(tx, id);
    if (element) {
      await putElement(tx, { id, element: { ...element, ...props } });
    }
  }
}

export async function positionElement(
  tx: WriteTransaction,
  { id, elementType, x, y }: { id: string; elementType?: string; x: number; y: number }
): Promise<void> {
  const element = await getElement(tx, id, elementType);
  if (element) {
    element.x = x;
    element.y = y;
    await putElement(tx, { id, element: element });
  }
}

function key(id: string): string {
  return `${canvasElementPrefix}${id}`;
}

export type NewElementData = {
  id: string;
  type: string;
  element: any; //CanvasElement;
};

export function copyElement(
  id: string,
  element: CanvasElement,
  offsetX: number = 50,
  offsetY: number = 50
): NewElementData {
  const [elementType] = id.split("-");
  return {
    id: createElementId(),
    type: elementType,
    element: {
      ...element,
      x: element.x + offsetX,
      y: element.y + offsetY,
    },
  };
}

interface PatchInfo {
  id: string;
  patch: Patch[];
}

export async function patchCanvasEl(tx: WriteTransaction, args: { changes: PatchInfo[] }) {
  // the single case is more common, do it first
  if (args.changes.length == 1) {
    const elKey = key(args.changes[0].id);
    const element = await tx.get(elKey);
    return element
      ? authenticatedPut(tx, elKey, applyPatches(element as any, args.changes[0].patch))
      : Promise.resolve();
  }
  const elements = await Promise.all(args.changes.map(({ id }) => tx.get(key(id))));
  let newElements = elements.map(
    (element, index) => element && applyPatches(element as any, args.changes[index].patch)
  );
  newElements = newElements.filter(Boolean); // remove undefined;
  await Promise.all(newElements.map((el, index) => authenticatedPut(tx, key(args.changes[index].id), el as any)));
}

// TODO
// this function is very similar to patchCanvasEl, they can be united into one I think
// Also, if user isn't permitted to write, authenticatedPut will not perform the write but the read
// and modify will still happen, which is wasteful. Better to check for write permissions first.
export async function patchAnything(tx: WriteTransaction, args: { changes: PatchInfo[] }) {
  if (args.changes.length == 1) {
    const key = args.changes[0].id;
    const value = (await tx.get(key)) ?? {};
    return authenticatedPut(tx, key, applyPatches(value as any, args.changes[0].patch));
  }
  const values = await Promise.all(args.changes.map(({ id }) => tx.get(id)));
  let newValues = values.map((value, index) => applyPatches((value as any) ?? {}, args.changes[index].patch));
  await Promise.all(newValues.map((value, index) => authenticatedPut(tx, args.changes[index].id, value as any)));
}
