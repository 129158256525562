import { useEffect, useRef, useState } from "react";
import tracking from "../tracking";
import consts from "../../shared/consts";
import * as Sentry from "@sentry/nextjs";
import { Reflect } from "@workcanvas/reflect/client";
import { M } from "shared/datamodel/mutators";
import { useSanityElement } from "../subscriptions";

export type SanityErrorReason = "initial connection failed" | "server error";

const SYNC_INTERVAL = 5000;
const MAX_ERRORS_THRESHOLD = 15000 / SYNC_INTERVAL; // 15 seconds
const DISCONNECTED_ERRORS_THRESHOLD = 60000 / SYNC_INTERVAL; // 60 seconds

const debugLog = (...args: any[]) => {
  console.log("[SanityCanvasElement]", ...args);
  /* if (process.env.NEXT_ENV && !["production", "staging"].includes(process.env.NEXT_ENV)) {
     console.log("[SanityCanvasElement]", ...args);
   }*/
};

/**
 * This hook is used to check the sanity of the reflect server.
 *
 * The purpose of this element is to make sure we quickly indetify cases where the DO for some reason
 * Stops to apply the user's mutation because of an error, and take action accordingly (e.g. show the user a modal to refresh)
 * We have several cases I can think of:
 *  1) The user is never able to connect to a canvas - Throw onError after 60 seconds
 *  2) The user successfully connects, and after some time the DO stops to process mutations - Throw onError after MAX_ERRORS_THRESHOLD
 *  3) The users switched between tabs (going offline and online when he comes back) - Do not try to sync when tab is hidden
 * If we identify error, what to do:
 *  1) Send mixpanel event
 *  2) Send sentry event
 *  3) invoke onError
 */
export default function useReflectSanity(
  reflect: Reflect<M> | null | undefined,
  isReadOnly: boolean
): SanityErrorReason | null {
  const lastMutationTimeStamp = useRef(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const failedAttemptsCount = useRef(0);
  const firstSyncSucceed = useRef(false);
  const [error, setError] = useState<SanityErrorReason | null>(null);

  /** Note: useSubscribe is being fired twice for each mutation:
   /* Once with the optimistic, client-side, mutation (without server ack)
   /* Second time when the server returns his authartive result after the mutation was applied
   */
  const sanityResult = useSanityElement(reflect);

  function clearSyncInterval() {
    if (intervalRef.current) {
      debugLog("clearing sanity interval");
      clearInterval(intervalRef.current);
    }
  }

  function handleError() {
    const nextErrorCount = failedAttemptsCount.current + 1;
    //Make sure to wait for 60 seconds for users who can't connect (addressing issues of long initial connection time or weak internet)
    const maxErrors = firstSyncSucceed.current ? MAX_ERRORS_THRESHOLD : DISCONNECTED_ERRORS_THRESHOLD;
    //Note: The time it takes us to throw onError depends on both MAX_ERRORS_THRESHOLD and TIMEOUT_INTERVAL
    //So, if TIMEOUT_INTERVAL is 5s and MAX_ERRORS_THRESHOLD is 3, we will throw after 15s of failing to get a valid response
    if (nextErrorCount > maxErrors) {
      debugLog("MAX_ERRORS_THRESHOLD reached, throwing onError", { nextErrorCount, maxErrors });
      const reason = maxErrors === MAX_ERRORS_THRESHOLD ? "server error" : "initial connection failed";
      tracking.trackEvent(consts.TRACKING_CATEGORY.CONNECTIVITY, "sanity-failed", reason);
      Sentry.addBreadcrumb({
        message: "Reflect server is down",
        category: "error",
        level: "error",
      });
      Sentry.captureException("Sanity failed", { tags: { type: "connectivity", reason } });
      setError(reason);
      clearSyncInterval();
      // close reflect instance to prevent calling infinite to /reps/auth on the server
      reflect?.close();
    } else {
      failedAttemptsCount.current = nextErrorCount;
      debugLog("new error count", failedAttemptsCount.current);
    }
  }

  function handleInvalidTimestamp(originTimestamp: number, sanityTimestamp: number, lastMutationTimeStamp: number) {
    //Shouldn't happen
    debugLog("oh oh, mutation timestamp is not right", { originTimestamp, sanityTimestamp, lastMutationTimeStamp });
    tracking.trackEvent(consts.TRACKING_CATEGORY.CONNECTIVITY, "sanity-check-invalid-timestamp");
    //handleError();
  }

  // Execute when sanityResult changes. It can be because of one of the 2 scenarios described above
  useEffect(() => {
    if (!sanityResult) {
      // Can happen only at the first init of the component, unexpected otherwise
      debugLog("sanityResult is undefined, should happen only on init");
      return;
    }

    const { originTimestamp, sanityTimestamp } = sanityResult;

    /** The server didn't apply the mutation yet.
     /* This can be legit if the client-side mutation triggered the subscription
     /* Wait a little to get the ack from the server or report a problem
     */
    if (sanityTimestamp === undefined) {
      debugLog("oh oh sanityTimestamp is undefined, calling handleError()", sanityResult);
      handleError();
    } else {
      debugLog("inside check", {
        lastMutationTimeStamp: lastMutationTimeStamp.current,
        ...sanityResult,
      });
      //Handle it as success, but log it for investigation
      if (originTimestamp > sanityTimestamp) {
        handleInvalidTimestamp(originTimestamp, sanityTimestamp, lastMutationTimeStamp.current);
      }
      failedAttemptsCount.current = 0;
      //Mark the firstSyncSucceeded flag so we can distinguish between users who never connected
      //to users who had issues while they are connected
      if (!firstSyncSucceed.current) {
        firstSyncSucceed.current = true;
      }
      debugLog("mutation was synced successfully");
    }
  }, [sanityResult]);

  //Make sure to execute setInterval only once for this component
  useEffect(() => {
    if (isReadOnly || !reflect) {
      // No need to check sanity for read-only users
      return;
    }

    intervalRef.current = setInterval(() => {
      if (isTabHidden()) {
        debugLog("Tab is hidden, skipping sanity check");
        return;
      }
      debugLog("begin sanityCheck interval");
      lastMutationTimeStamp.current = Date.now();
      reflect.mutate.sanityCheck({ originTimestamp: lastMutationTimeStamp.current });
    }, SYNC_INTERVAL);

    return () => {
      clearSyncInterval();
    };
  }, [reflect, isReadOnly]);

  return error;
}

function isTabHidden(): boolean {
  type VisibilityState = {
    hidden?: string;
    visibilityChange?: string;
    visibilityState?: string;
  };

  const visibilityProps: VisibilityState = (() => {
    // Check for standard properties first
    if (typeof document.hidden !== "undefined") {
      return {
        hidden: "hidden",
        visibilityChange: "visibilitychange",
        visibilityState: "visibilityState",
      };
    }

    // Check for vendor-prefixed properties
    const prefixes = ["webkit", "moz", "ms", "o"];
    for (const prefix of prefixes) {
      const prefixedHidden = `${prefix}Hidden`;
      const prefixedVisibilityChange = `${prefix}visibilitychange`;
      const prefixedVisibilityState = `${prefix}VisibilityState`;

      if (typeof (document as any)[prefixedHidden] !== "undefined") {
        return {
          hidden: prefixedHidden,
          visibilityChange: prefixedVisibilityChange,
          visibilityState: prefixedVisibilityState,
        };
      }
    }

    // Fallback to standard property names
    return {
      hidden: "hidden",
      visibilityChange: "visibilitychange",
      visibilityState: "visibilityState",
    };
  })();

  const hiddenProp = visibilityProps.hidden;
  if (typeof hiddenProp !== "undefined" && document[hiddenProp as keyof Document] !== undefined) {
    return Boolean(document[hiddenProp as keyof Document]);
  }
  return false;
}
